var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{ref:"container",staticClass:"py-0 px-0",attrs:{"fluid":""}},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":""}},[_c('v-tab',{attrs:{"to":{
                name: 'leads-inprogress',
                params: { ruta: 'inProgress' },
            }}},[_vm._v(" ACTIVE ")]),_c('v-tab',{attrs:{"to":{
                name: 'leads-onhold',
                params: { ruta: 'onHold' },
            }}},[_vm._v(" ON HOLD ")]),_c('v-tab',{attrs:{"to":{
                name: 'leads-approved',
                params: { ruta: 'approved' },
            }}},[_vm._v(" APPROVED ")]),_c('v-tab',{attrs:{"to":{
                name: 'leads-rejected',
                params: { ruta: 'rejected' },
            }}},[_vm._v(" REJECTED ")])],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"overflow-y-auto px-4 pt-4",style:(`height: ${_vm.height}px`)},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }